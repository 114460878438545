import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/login/Index'),
    },
    {
      path: '/home',
      component: () => import('@/views/dashboard/Index'),
      redirect: '/home/dashboard',
      children: [
        // Dashboard
        {
          name: 'dashboard',
          path: '/home/dashboard',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        // Pages
        {
          name: 'clients',
          path: '/home/clients',
          component: () => import('@/views/dashboard/pages/Clients'),
        },
        {
          name: 'client',
          path: '/home/client',
          component: () => import('@/views/dashboard/pages/Client'),
        },
        {
          name: 'support',
          path: '/home/support',
          component: () => import('@/views/dashboard/pages/Support'),
        },
      ],
    },
  ],
})
