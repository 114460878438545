<template>
  <router-view />
</template>

<style>
.app-modal {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.app-modal-title {
  padding: 20px;
  text-align: center;
}

.app-modal-body {
  padding: 20px;
  height: 100%;
}

.app-modal-footer {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}

.app-modal-footer button:first-child {
  margin-right: 20px;
}
</style>

<script>
  import moment from 'moment'

  export default {
    name: 'App',
    beforeMount: function () {
      window.language = 'pt'
      window.strings = {}
      window.diffUtcHours = this.getDiffUtcHours()
      this.$vuetify.theme.dark = localStorage.getItem('dashboard_theme') === 'dark' ? true : false
    },
    methods: {
      getDiffUtcHours: function () {
        const format = 'YYYY-MM-DDTHH:mm:ss'
        const localTime = moment(moment().format(format), format)
        const utcTime = moment(moment.utc().format(format), format)
        return localTime.diff(utcTime, 'hours')
      },
    },
  }
</script>
